import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { MetaMaskButton } from "@metamask/sdk-react-ui"
import { ethers } from "ethers";

const tokenABI = [
  { "inputs": [], "stateMutability": "nonpayable", "type": "constructor" },
  {
    "inputs": [
      { "internalType": "address", "name": "spender", "type": "address" },
      { "internalType": "uint256", "name": "allowance", "type": "uint256" },
      { "internalType": "uint256", "name": "needed", "type": "uint256" }
    ],
    "name": "ERC20InsufficientAllowance",
    "type": "error"
  },
  {
    "inputs": [
      { "internalType": "address", "name": "sender", "type": "address" },
      { "internalType": "uint256", "name": "balance", "type": "uint256" },
      { "internalType": "uint256", "name": "needed", "type": "uint256" }
    ],
    "name": "ERC20InsufficientBalance",
    "type": "error"
  },
  {
    "inputs": [{ "internalType": "address", "name": "approver", "type": "address" }],
    "name": "ERC20InvalidApprover",
    "type": "error"
  },
  {
    "inputs": [{ "internalType": "address", "name": "receiver", "type": "address" }],
    "name": "ERC20InvalidReceiver",
    "type": "error"
  },
  {
    "inputs": [{ "internalType": "address", "name": "sender", "type": "address" }],
    "name": "ERC20InvalidSender",
    "type": "error"
  },
  {
    "inputs": [{ "internalType": "address", "name": "spender", "type": "address" }],
    "name": "ERC20InvalidSpender",
    "type": "error"
  },
  {
    "anonymous": false,
    "inputs": [
      { "indexed": true, "internalType": "address", "name": "owner", "type": "address" },
      { "indexed": true, "internalType": "address", "name": "spender", "type": "address" },
      { "indexed": false, "internalType": "uint256", "name": "value", "type": "uint256" }
    ],
    "name": "Approval",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      { "indexed": true, "internalType": "address", "name": "from", "type": "address" },
      { "indexed": true, "internalType": "address", "name": "to", "type": "address" },
      { "indexed": false, "internalType": "uint256", "name": "value", "type": "uint256" }
    ],
    "name": "Transfer",
    "type": "event"
  },
  {
    "inputs": [
      { "internalType": "address", "name": "owner", "type": "address" },
      { "internalType": "address", "name": "spender", "type": "address" }
    ],
    "name": "allowance",
    "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      { "internalType": "address", "name": "spender", "type": "address" },
      { "internalType": "uint256", "name": "value", "type": "uint256" }
    ],
    "name": "approve",
    "outputs": [{ "internalType": "bool", "name": "", "type": "bool" }],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [{ "internalType": "address", "name": "account", "type": "address" }],
    "name": "balanceOf",
    "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }],
    "stateMutability": "view",
    "type": "function"
  },
  { "inputs": [], "name": "decimals", "outputs": [{ "internalType": "uint8", "name": "", "type": "uint8" }], "stateMutability": "view", "type": "function" },
  { "inputs": [], "name": "name", "outputs": [{ "internalType": "string", "name": "", "type": "string" }], "stateMutability": "view", "type": "function" },
  { "inputs": [], "name": "symbol", "outputs": [{ "internalType": "string", "name": "", "type": "string" }], "stateMutability": "view", "type": "function" },
  { "inputs": [], "name": "totalSupply", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
  {
    "inputs": [
      { "internalType": "address", "name": "to", "type": "address" },
      { "internalType": "uint256", "name": "value", "type": "uint256" }
    ],
    "name": "transfer",
    "outputs": [{ "internalType": "bool", "name": "", "type": "bool" }],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      { "internalType": "address", "name": "from", "type": "address" },
      { "internalType": "address", "name": "to", "type": "address" },
      { "internalType": "uint256", "name": "value", "type": "uint256" }
    ],
    "name": "transferFrom",
    "outputs": [{ "internalType": "bool", "name": "", "type": "bool" }],
    "stateMutability": "nonpayable",
    "type": "function"
  }
];

async function sendChoiceTokens1() {
  try {
    console.log("Button clicked!");
    if (!window.ethereum) {
      console.error("Install Metamask.");
      return;
    }
    // Request account access
    const accounts = await window.ethereum.request({ method: "eth_requestAccounts" });
    const senderAddress = accounts[0];
    console.log("Connected MetaMask account:", senderAddress);
    // Connect to the provider
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    // Ensure the user is on the correct network
    const network = await provider.getNetwork();
    if (network.chainId !== 8453) { // Replace with your network's chain ID
      alert("Please switch to the correct network in MetaMask.");
      return;
    }
    // Define the CHOICE token contract
    const choiceTokenAddress = "0x72b423D98D98D509e66E9C565873C3E63Ee4C7ab"; // Replace with the actual address
    const choiceTokenContract = new ethers.Contract(choiceTokenAddress, tokenABI, signer);
    // Define the recipient and amount
    const recipientAddress1 = "0xd26d7Ba9802d8e84b7BcC3818f0e1C7014CB8392"; // Replace with the actual recipient address
    const tokenAmount = ethers.utils.parseUnits("50000", 18); // Adjust decimals if needed
    console.log(`Sending tokens to: ${recipientAddress1}, Amount: ${tokenAmount.toString()}`);
    // Call the transfer function
    const tx = await choiceTokenContract.transfer(recipientAddress1, tokenAmount);
    console.log("Transaction sent. Waiting for confirmation...");
    await tx.wait();
    alert("Transaction successful!");
    console.log("Transaction details:", tx);
  } catch (error) {
    console.error("Error during transaction:", error);
    alert(`Transaction failed: ${error.message}`);
  }
}

async function sendChoiceTokens2() {
  try {
    console.log("Button clicked!");
    if (!window.ethereum) {
      console.error("Install Metamask.");
      return;
    }
    // Request account access
    const accounts = await window.ethereum.request({ method: "eth_requestAccounts" });
    const senderAddress = accounts[0];
    console.log("Connected MetaMask account:", senderAddress);
    // Connect to the provider
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    // Ensure the user is on the correct network
    const network = await provider.getNetwork();
    if (network.chainId !== 8453) { // Replace with your network's chain ID
      alert("Please switch to the correct network in MetaMask.");
      return;
    }
    // Define the CHOICE token contract
    const choiceTokenAddress = "0x72b423D98D98D509e66E9C565873C3E63Ee4C7ab"; // Replace with the actual address
    const choiceTokenContract = new ethers.Contract(choiceTokenAddress, tokenABI, signer);
    // Define the recipient and amount
    const recipientAddress1 = "0x8aeFDb3E82372648b4cB345df6F90bf028EC20f8"; // Replace with the actual recipient address
    const tokenAmount = ethers.utils.parseUnits("50000", 18); // Adjust decimals if needed
    console.log(`Sending tokens to: ${recipientAddress1}, Amount: ${tokenAmount.toString()}`);
    // Call the transfer function
    const tx = await choiceTokenContract.transfer(recipientAddress1, tokenAmount);
    console.log("Transaction sent. Waiting for confirmation...");
    await tx.wait();
    alert("Transaction successful!");
    console.log("Transaction details:", tx);
  } catch (error) {
    console.error("Error during transaction:", error);
    alert(`Transaction failed: ${error.message}`);
  }
}

async function sendChoiceTokens3() {
  try {
    console.log("Button clicked!");
    if (!window.ethereum) {
      console.error("Install Metamask.");
      return;
    }
    // Request account access
    const accounts = await window.ethereum.request({ method: "eth_requestAccounts" });
    const senderAddress = accounts[0];
    console.log("Connected MetaMask account:", senderAddress);
    // Connect to the provider
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    // Ensure the user is on the correct network
    const network = await provider.getNetwork();
    if (network.chainId !== 8453) { // Replace with your network's chain ID
      alert("Please switch to the correct network in MetaMask.");
      return;
    }
    // Define the CHOICE token contract
    const choiceTokenAddress = "0x72b423D98D98D509e66E9C565873C3E63Ee4C7ab"; // Replace with the actual address
    const choiceTokenContract = new ethers.Contract(choiceTokenAddress, tokenABI, signer);
    // Define the recipient and amount
    const recipientAddress1 = "0x16a7b2211af35A6AA472c28aE79Ba86EbF200B65"; // Replace with the actual recipient address
    const tokenAmount = ethers.utils.parseUnits("20000", 18); // Adjust decimals if needed
    console.log(`Sending tokens to: ${recipientAddress1}, Amount: ${tokenAmount.toString()}`);
    // Call the transfer function
    const tx = await choiceTokenContract.transfer(recipientAddress1, tokenAmount);
    console.log("Transaction sent. Waiting for confirmation...");
    await tx.wait();
    alert("Transaction successful!");
    console.log("Transaction details:", tx);
  } catch (error) {
    console.error("Error during transaction:", error);
    alert(`Transaction failed: ${error.message}`);
  }
}

async function sendChoiceTokens4() {
  try {
    console.log("Button clicked!");
    if (!window.ethereum) {
      console.error("Install Metamask.");
      return;
    }
    // Request account access
    const accounts = await window.ethereum.request({ method: "eth_requestAccounts" });
    const senderAddress = accounts[0];
    console.log("Connected MetaMask account:", senderAddress);
    // Connect to the provider
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    // Ensure the user is on the correct network
    const network = await provider.getNetwork();
    if (network.chainId !== 8453) { // Replace with your network's chain ID
      alert("Please switch to the correct network in MetaMask.");
      return;
    }
    // Define the CHOICE token contract
    const choiceTokenAddress = "0x72b423D98D98D509e66E9C565873C3E63Ee4C7ab"; // Replace with the actual address
    const choiceTokenContract = new ethers.Contract(choiceTokenAddress, tokenABI, signer);
    // Define the recipient and amount
    const recipientAddress1 = "0xF045692f0029D869c4ed48164ffF611D192e63Ea"; // Replace with the actual recipient address
    const tokenAmount = ethers.utils.parseUnits("20000", 18); // Adjust decimals if needed
    console.log(`Sending tokens to: ${recipientAddress1}, Amount: ${tokenAmount.toString()}`);
    // Call the transfer function
    const tx = await choiceTokenContract.transfer(recipientAddress1, tokenAmount);
    console.log("Transaction sent. Waiting for confirmation...");
    await tx.wait();
    alert("Transaction successful!");
    console.log("Transaction details:", tx);
  } catch (error) {
    console.error("Error during transaction:", error);
    alert(`Transaction failed: ${error.message}`);
  }
}

////////////////////////
////////////////////////
function Home2() {
////////////////////////
////////////////////////

  ////////////////////////
  ////////////////////////
  ////////////////////////
  ////////////////////////

  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <p>
              <MetaMaskButton theme={"dark"} color="blue"></MetaMaskButton>
            </p>
            <h1 style={{ fontSize: "2.6em" }}>
              <span className="blue"> Governance </span> 
            </h1>
            <h2 style={{ fontSize: "1.6em" }}>
              <span className="blue"> Should Base be The Official Main Chain for Choice? </span> 
            </h2>
            <p>
            </p>
            
            <p>
              <button id="button1" onClick={sendChoiceTokens1}>Yes</button>
            </p>
            <p>
              <button id="button1" onClick={sendChoiceTokens2}>No</button>
            </p>


          </Col>

        </Row>
        
      </Container>
    </Container>
  );
}
export default Home2;
